export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img\header-1-removebg-preview.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">About</p>
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
          My name is Shahid Zafar, I'm a Mern-Stack Developer based in Faisalabad, Pakistan. I have developed many types of front-ends and back-ends  from well know Shahid. applications to Ecommerce booking platforms.
          </p>
          <p className="hero--section-description">
          I'm passionate about cutting-edge, pixel-perfect, beautiful interfaces and intuitively implemented UX. 
          </p>
        </div>
      </div>
    </section>
  );
}
